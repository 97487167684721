<template>
  <div class="profile__wrapper">
    <div class="" v-if="isVoted">
      <h5 class="title-h5 profile__info-title">
        {{ $t('categorization.categorization_vote_counted') }} {{ votingData }}
      </h5>
      <div class="profile__info">
        {{ $t('categorization.categorization_voting_info.txt_0') }}
        <span class="profile__link" @click="preliminaryResultsHandler">{{
          $t('categorization.categorization_voting_info.link')
        }}</span>
        {{ $t('categorization.categorization_voting_info.txt_1') }}
      </div>
    </div>

    <template v-if="structure">
      <div class="categorisation categorisation__wrapper">
        <div
          class="profile__section"
          :style="{ order: group.order_position }"
          v-for="(group, id_0) in structure"
          :key="`${group.uuid}${id_0}`"
        >
          <h4 class="title-h4 profile__title" v-if="!!group.title">
            {{ group.title }}
          </h4>
          <div
            class="categorisation__block"
            :style="{ order: subgroup.order_position }"
            v-for="(subgroup, id_1) in group.subgroups"
            :key="`${subgroup.uuid}${id_1}`"
          >
            <h5 class="title-h5 profile__subtitle" v-if="!!subgroup.title">
              {{ subgroup.title }}:
            </h5>
            <div
              :class="[
                subgroup.criterias[0].type === 'images'
                  ? 'image__grid'
                  : 'profile__grid',
              ]"
            >
              <div
                v-for="(item, id_2) in subgroup.criterias"
                :key="`${item.uuid}${id_2}`"
                :class="`${item.classnames}`"
                :style="{ order: item.order_position }"
                :id="item.uuid"
              >
                <text-field
                  v-if="['text', 'number', 'rating'].includes(item.type)"
                  v-model="item['value']"
                  :is-error="item.error == true"
                  :stars-read-only="editIsDisabled(item.uuid)"
                  :title="item.title"
                  :is-rate="item.type === 'rating'"
                  :is-icon="item.type === 'text_icon'"
                  :value-mask="item.mask"
                  :is-inactive="editIsDisabled(item.uuid)"
                  icon-name="actual"
                  :can-comment="canComment"
                  is-commentable
                  :uuid="item.uuid"
                />
                <FileUpload
                  v-else-if="['file', 'files'].includes(item.type)"
                  :id="item.uuid"
                  v-model="item['value']"
                  :title="item.title"
                  :is-multiple="item.type == 'files'"
                  :is-download-only="editIsDisabled(item.uuid)"
                  is-commentable
                  :uuid="item.uuid"
                  :can-comment="canComment"
                />
                <PhotoUpload
                  v-else-if="['images'].includes(item.type)"
                  :id="item.uuid"
                  :uuid="item.uuid"
                  v-model="item['value']"
                  :photos-uploaded="item['value']"
                  :title="item.title"
                  is-multiple
                  is-commentable
                  :is-disabled="editIsDisabled(item.uuid)"
                  :can-comment="canComment"
                />
                <div
                  :class="`${item.classnames}`"
                  v-else-if="item.type === 'switcher'"
                >
                  <Switcher
                    :uuid="item.uuid"
                    :can-comment="canComment"
                    :is-commentable="commentsVisible"
                    :is-inactive="true"
                    v-model="item.value"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="voteSchedule !== 'none'" class="voting-date__block">
        <div v-if="voteSchedule === 'edit'" class="voting-date__block">
          <h4 class="title-h4 profile__title">
            {{ $t('categorization.voting_date_title') }}
          </h4>
          <p class="subtitle">
            {{ $t('categorization.voting_date_subtitle') }}
          </p>
        </div>
        <div v-if="voteSchedule === 'view'" class="voting-date__block">
          <h4 class="title-h4 profile__title">
            {{ $t('categorization.voting_date_title_view') }}
          </h4>
          <p class="subtitle">
            {{ $t('categorization.voting_date_subtitle_view') }}
          </p>
        </div>
        <FileUpload
          v-model="uploadedSchedule"
          class="schedule__upload"
          :is-multiple="false"
          :valid-formats="['doc', 'docx', 'pdf', 'img', 'jpeg', 'jpg', 'png']"
          :is-download-only="voteSchedule == 'view'"
          :max-size-k-b="25000"
          @dropError="uploadedScheduleError = false"
        />
        <div class="">
          <DatePicker
            v-model="votingDate"
            :format="'DD-MM-YYYY'"
            value-type="YYYY-MM-DD HH:mm:ss"
            lang="datePickerConfig"
            :disabled="voteSchedule === 'view'"
            :placeholder="$t(`categorization.voting_date_placeholder`)"
          />
        </div>
      </div>
    </template>
    <template v-else>
      <LoaderContent />
    </template>
    <div class="no-print">
      <ButtonsBlock
        :buttons="buttonsArray"
        :disabledVote="isAfterToday"
        :disabledDownLoad="!isCertificate"
        :disabledAchive="!isAchive"
        @print="print()"
        @downloadCertificate="downloadCertificate()"
        @preliminaryResults="preliminaryResultsHandler()"
        @vote="voteHandler()"
        @downloadPDF="downloadPDF()"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  checkIsSameDate,
  checkIsDateAfterToday,
  convertDateToCurrentTimeZone,
  checkIsDateSameOrBeforeToday,
} from '@/library/time';
import { getCategorizationFormStructure } from '@/api/services/hotel.js';
import DatePicker from 'vue2-datepicker';
import FileUpload from '@/elements/FileUpload.vue';
import PhotoUpload from '@/elements/PhotoUpload.vue';
import ButtonsBlock from '@/elements/ButtonsBlock.vue';
import Switcher from '@/elements/Switcher.vue';
import LoaderContent from '@/elements/LoaderContent.vue';

export default {
  components: {
    FileUpload,
    PhotoUpload,
    DatePicker,
    Switcher,
    ButtonsBlock,
    LoaderContent,
  },
  data() {
    return {
      buttonsArray: [],
      commentsVisible: false,
      canComment: false,
      canEdit: '',
      voteSchedule: '',
      alreadyVoted: false,
      dataToSend: new FormData(),
      uploadedSchedule: [],
      votingDate: '',
      structure: null,
    };
  },

  async created() {
    let categorizationRequestUUUID = this.$route.params.id;
    await this.getCategorizationStructure();

    await this.$store.dispatch(
      'getCategorizationCommissioner',
      categorizationRequestUUUID
    );

    this.statusModelHandler();
    if (this.commentsVisible) {
      await this.$store.dispatch(
        'getCommentsCommissioner',
        categorizationRequestUUUID
      );
    }

    this.populateFormWithData();
    this.getScheduleData();
  },

  computed: {
    ...mapGetters([
      'getCategorizationCommissioner',
      'getCommentsCommissioner',
      'getStatusModel',
    ]),

    prefilledCriteris() {
      if (this.$route.meta.status != 'create') {
        let prefilledCriteris = {};
        this.getCategorizationCommissioner.criteria.forEach((criteria) => {
          prefilledCriteris[criteria.categorization_criterion_uuid] = criteria;
        });
        return prefilledCriteris;
      } else return {};
    },

    ifToday() {
      return checkIsSameDate(this.getCategorizationCommissioner?.voting_at);
    },

    isAfterToday() {
      return checkIsDateAfterToday(
        this.getCategorizationCommissioner?.voting_at
      );
    },

    isVoted() {
      return (
        this.getCategorizationCommissioner?.is_voted && !this.isFinishedSigned
      );
    },

    ifBeforeOrToday() {
      return checkIsDateSameOrBeforeToday(
        this.getCategorizationCommissioner?.voting_at
      );
    },

    isFinishedSigned() {
      return (
        this.getCategorizationCommissioner?.voting.status === 'finished_signed'
      );
    },

    votingData() {
      return convertDateToCurrentTimeZone(
        this.getCategorizationCommissioner?.voting_at
      ).date;
    },

    isCertificate() {
      return !!this.getCategorizationCommissioner?.certificate;
    },

    isAchive() {
      return !!this.getCategorizationCommissioner?.archive;
    },
  },

  methods: {
    async getCategorizationStructure() {
      let data = { lang: 'uk', uuid: this.$route.params.id };
      try {
        let res;
        res = await getCategorizationFormStructure(data);
        if (res.status === 200) {
          this.structure = res.data.data.structure;
        }
      } catch (e) {
        console.log('>>> error getCategorizationFormStructure', e.response);
      }
    },

    print() {
      window.print();
    },

    statusModelHandler() {
      this.getStatusModel.forEach((element) => {
        if (
          element.filing_status ==
            this.getCategorizationCommissioner.filing_status &&
          element.review_status ==
            this.getCategorizationCommissioner.review_status
        ) {
          this.commentsVisible = element.comments_visible;
          this.canComment =
            element.can_comment ||
            (this.ifToday &&
              this.getCategorizationCommissioner.review_status ==
                'waiting_for_voting');
          this.canEdit = element.edit;
          this.voteSchedule = element.schedule;

          // this.buttonsArray = this.getCategorizationCommissioner?.is_voted
          //       ? [...element.buttons.buttonsDuringVoting]
          //       : [...element.buttons.buttonsBeforeVoting];

          if (Array.isArray(element.buttons)) {
            this.buttonsArray = element.buttons;
          } else {
            this.buttonsArray = this.getCategorizationCommissioner?.is_voted
              ? [...element.buttons.buttonsDuringVoting]
              : [...element.buttons.buttonsBeforeVoting];
          }
        }
      });
    },

    editIsDisabled(uuid) {
      let editIsDisabled = false;
      switch (this.canEdit) {
        case 'can_edit_all_fields':
          editIsDisabled = false;
          break;
        case 'not_allowed':
          editIsDisabled = true;
          break;
        case 'can_edit_commented_fields':
          this.getCommentsHotel[uuid]
            ? (editIsDisabled = false)
            : (editIsDisabled = true);
          break;
      }
      return editIsDisabled;
    },

    populateFormWithData() {
      this.structure.map((el) =>
        el.subgroups.map((s) =>
          s.criterias.map(async (s) => {
            if (s.uuid in this.prefilledCriteris) {
              if (Array.isArray(this.prefilledCriteris[s.uuid].value)) {
                let valueToPopulate = [];

                for (let file in this.prefilledCriteris[s.uuid].value) {
                  let convertedFile = await this.createFile(
                    this.prefilledCriteris[s.uuid].value[file].original_url,
                    this.prefilledCriteris[s.uuid].value[file].mime
                  );
                  valueToPopulate.push(convertedFile);
                }
                s.value = valueToPopulate;
              } else {
                s.value = this.prefilledCriteris[s.uuid].value;
              }
            }
          })
        )
      );
    },

    async getScheduleData() {
      this.votingDate = !!this.getCategorizationCommissioner?.voting_at
        ? this.getCategorizationCommissioner?.voting_at
        : this.votingDate;

      this.uploadedSchedule = !!this.getCategorizationCommissioner
        ?.schedule_attachment?.length
        ? [
            await this.createFile(
              this.getCategorizationCommissioner.schedule_attachment[0]
                .original_url,
              this.getCategorizationCommissioner.schedule_attachment[0].mime
            ),
          ]
        : this.uploadedSchedule;
    },

    async createFile(url, type) {
      const response = await fetch(url);
      const data = await response.blob();
      const metadata = {
        type: type,
      };
      return new File([data], url.split('/').at(-1), metadata);
    },

    preliminaryResultsHandler() {
      this.$router.push({
        name: 'commissioner_voting-results',
        params: { id: this.getCategorizationCommissioner.voting.uuid },
      });
    },

    voteHandler() {
      this.$store.commit('modal/SET_MODAL', {
        state: true,
        name: 'vote',
        size: 'xxl',
        handler: {
          payload: this.getCategorizationCommissioner.voting.uuid,
          role: 'Commissioner',
        },
      });
    },

    groupGrid(group) {
      if (group.criterias[0].type == 'images') {
        return 'image__grid';
      }
      return 'profile__grid';
    },

    downloadCertificate() {
      this.$store.dispatch(
        'downloadFile',
        this.getCategorizationCommissioner.certificate.attachment.original_url
      );
    },

    downloadPDF() {
      this.$store.dispatch(
        'downloadFile',
        this.getCategorizationCommissioner.archive.original_url
      );
    },
  },
};
</script>

<style lang="sass" scoped>
.voting-date__block
  display: flex
  align-items: center
  flex-direction: column
  margin-top: 4rem
.schedule__upload
  max-width: 40rem
  margin: 7rem 0
.mx-datepicker
  max-width: 40rem
.control__btn-wrapper
  margin-top: 8rem
.profile__info
  margin-bottom: 50px
.profile__info-title
  margin-bottom: 20px
.profile__link
  color: $gray
  cursor: pointer
  transition: color 0.15s
  &:hover
    color: $gray_active
    transition: color 0.25s
    text-decoration: underline
</style>
